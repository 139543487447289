<template>
    <TabNavsWrapper :disabled="!noWrap">
        <ul v-if="compactView == false" ref="tabsRef" :class="[`o365-tabs-${tabsControl.tabsId}`, tabsClass]"
            :style="computedTabsStyle" role="tablist" v-bind="$attrs" v-show="!hideNavbar"
            @wheel.prevent="(event) => handleMouseOver(event)" class="tabsContainer">

            <button v-if="showLeftScroll && showScrollArrows" class="left-arrow" @click="handleScrollClick('left')" @mousedown="handleScrollLongPress('left')"
                @mouseup="clearScrollInterval" @mouseout="clearScrollInterval">
                <i class="bi bi-caret-left-fill"></i>
            </button>
            <slot name="beforeNav"></slot>
            <template v-for="(element, index) in tabsControl.tabs">
                <li class="nav-item" role="presentation" :class="element.itemClass" :style="element.itemStyle">
                    <button class="nav-link" :class="[{ active: element.active }, element.linkClass]"
                        :style="{ ...element.linkStyle, ...{ marginBottom: props.noWrap ? '0px' : null } }"
                        :disabled="element.props.disabled" type="button" role="tab" :data-bs-target="`#${element.id}`"
                        :aria-selected="element.active" @click="handleTabClick($event, index)">
                        <component v-if="element.titleSlot" :is="element.titleSlot" />
                        <template v-else>
                            {{ element.title }}
                        </template>
                    </button>
                </li>
            </template>
            <slot name="afterNav"></slot>
            <button v-if="showRightScroll && showScrollArrows" class="scroll-arrows right-arrow"
                @mousedown="handleScrollLongPress('right')" @click="handleScrollClick('right')" @mouseup="clearScrollInterval" @mouseout="clearScrollInterval">
                <i class="bi bi-caret-right-fill"></i>
            </button>
        </ul>
    </TabNavsWrapper>
    <div class="tab-content accordion" ref="tabContentRef"
        :class="[{ 'row-container': !compactView, 'px-1': compactView }, tabContentClass]" :style="tabContentStyle">
        <slot></slot>
    </div>
</template>         

<script setup> 
    import { InjectionKeys } from 'o365-utils';
    import TabControl from './Tabs.TabControl.ts';
    import { ref, provide, onMounted, nextTick, defineExpose, computed, h } from 'vue';

    const props = defineProps({
        tabsClass: {
            type: String,
            default: 'nav nav-tabs'
        },
        tabsStyle: null,
        tabContentClass: null,
        tabContentStyle: null,
        hideNavbar: Boolean,
        persistentKey: String,
        enableCompactView: Boolean,
        enableUrlFilter: {
            type: Boolean
        },
        id: String,
        sortCompareFn: Function,
        compactViewMode: {
            type: String,
            default: 'accordion'
        },
        noWrap: {
            type: Boolean,
            default: false
        }
    });

    const emit = defineEmits(['onShow','onShown','onHide','onHidden']);

    const lg = ref(false);
    const md = ref(false);
    const sm = ref(false);
    const showScrollArrows = ref(false)
    const showLeftScroll = ref(false);
    const showRightScroll = ref(false);

    const intervalId = ref(null);

    const tabContentRef = ref(null)
    const tabsRef = ref(null);
    const transitionPromise = ref(null);
    const tabsControl = ref(new TabControl({
        persistentKey: props.persistentKey,
        enableUrlFilter: props.enableUrlFilter,
        id: props.id
    }));

    const noWrapTabsStyle = ref({
        flexWrap: 'nowrap !important',
        whiteSpace: 'nowrap !important',
        overflowX: 'auto'

    });
    const computedTabsStyle = computed(() => {
        if (props?.tabsStyle !== undefined || props?.tabsStyle !== null) {
            return props.noWrap ? { ...props.tabsStyle, ...noWrapTabsStyle.value } : props.tabsStyle;
        } else {
            return props.noWrap ? noWrapTabsStyle.value : null;
        }
    });


    tabsControl.value.compactViewMode = props.compactViewMode;

    const compactView = ref(false);

    provide(InjectionKeys.tabControlKey, tabsControl);
    provide(InjectionKeys.bootstrapTransitionPromiseKey, transitionPromise);

    async function handleTabClick(e, index) {
        e.preventDefault();
        transitionPromise.value = new Promise((res) => {
            resolveTransition = res;
        });

        tabsControl.value.tabs[index].rendered = true;
        await nextTick();

        tabsControl.value.setActiveTab(index);

        const target = e.target.closest('button.nav-link');
        const tabControl = bootstrap.Tab.getOrCreateInstance(target);
        tabControl.show();
    }

    async function setActiveTab(id) {
        const index = tabsControl.value.tabs.findIndex(x => x.id === id);
        if (index === -1) {
            console.warn('Tab with id not found', id);
        } else {
            const element = tabsRef.value.querySelectorAll('button.nav-link')[index];
            handleTabClick({preventDefault: ()=>{}, target: element}, index);
        }
    }

    function getActiveTab() {
        const tab = tabsControl.value.tabs.find(x => x.active == true);
        return tab ? tab.id : null;
    }

    function resolveBreakpoints(){
        if(tabContentRef.value.clientWidth <= 576){
            sm.value = true;
            md.value = false;
            lg.value = false;
        } else if(tabContentRef.value.clientWidth > 576 && tabContentRef.value.clientWidth <= 768){
            sm.value = false;
            md.value = true;
            lg.value = false;
        } else if(tabContentRef.value.clientWidth > 768){
            sm.value = false;
            md.value = false;
            lg.value = true;
        }
    }

    function computeCompactView(){
        resolveBreakpoints();

        if(!props.enableCompactView){
            return; 
        }
        if(tabContentRef.value.clientWidth < 992){
            tabsControl.value.compactView = true;
            compactView.value = true;
        } else {
            tabsControl.value.compactView = false;
            compactView.value = false;
        }
    }

    let resolveTransition = null;
    onMounted(() => {
        computeCompactView();

        if (props.sortCompareFn) {
            tabsControl.value.sortTabs(props.sortCompareFn);
        }

        tabsRef.value.addEventListener('show.bs.tab', event => {
            emit('onShow',{activeTab:event.target, previousTab: event.relatedTarget});
        });

        tabsRef.value.addEventListener('shown.bs.tab', event => {
            emit('onShown',{activeTab:event.target, previousTab: event.relatedTarget});
            // Temp hack to force grid virtual scroll height reset
            window.dispatchEvent(new Event('resize'));
            tabsControl.value.cacheHiddenTab();
            if (resolveTransition) { resolveTransition(); }
            transitionPromise.value = null;
        });

        tabsRef.value.addEventListener('hide.bs.tab', event => {
            emit('onHide',{previousTab:event.target, activeTab: event.relatedTarget});
        });

        tabsRef.value.addEventListener('hidden.bs.tab', event => {
            emit('onHidden',{previousTab:event.target, activeTab: event.relatedTarget});
        });

        let updateDebounce = null;
        window.addEventListener('resize', function(event) {
            detectOverflow(tabsRef.value)
            if (updateDebounce) { window.clearTimeout(updateDebounce); }
            updateDebounce = window.setTimeout(() => {
                if(!tabContentRef.value){
                    return;
                }
                computeCompactView();
            }, 100);
        });

        if (props.noWrap) {
            tabsRef.value.addEventListener('scroll', detectZeroScroll);
            setTimeout(() => {
                detectOverflow(tabsRef.value);
            }, 300)
        }
    });

    const handleMouseOver = (e) => {
        const scrollAmount = e.deltaY;
        scrollHorizontally(scrollAmount);
    }

    const scrollHorizontally = (amount) => {
        if (!amount) {
            amount = 20;
        }
        if (tabsRef.value) {
            const currentScrollLeft = tabsRef.value.scrollLeft;
            const newScrollLeft = currentScrollLeft + amount;
            tabsRef.value.scrollTo({
                left: newScrollLeft,
                behavior: 'auto'
            });
        }
        detectZeroScroll();
    }
    const detectOverflow = (element) => {
        if (!props.noWrap) {
            return;
        }
        if (element.scrollWidth > element.clientWidth) {
            showLeftScroll.value = true;
            showRightScroll.value = true;
            showScrollArrows.value = true;
        } else {
            showLeftScroll.value = false;
            showRightScroll.value = false;
            showScrollArrows.value = false;
        }
        detectZeroScroll();
    }

    const detectZeroScroll = () => {
        if (!props.noWrap) {
            return;
        }
        const currentScrollLeft = tabsRef.value.scrollLeft;
        if (currentScrollLeft === 0) {
            clearInterval(intervalId.value)
            showLeftScroll.value = false;
        } else {
            showLeftScroll.value = true;
        }

        if (currentScrollLeft === tabsRef.value.scrollWidth - tabsRef.value.clientWidth) {
            clearInterval(intervalId.value)
            showRightScroll.value = false;
        } else {
            showRightScroll.value = true;
        }

    }


    const handleScrollClick = (direction) => {
        if (direction === 'right') {
            scrollHorizontally(30);
        } else if (direction === 'left') {
            scrollHorizontally(-30);
        }
    }

    const handleScrollLongPress = (direction) => {
        if (direction === 'right') {
            intervalId.value = setInterval(() => scrollHorizontally(30), 100);
        } else if (direction === 'left') {
            intervalId.value = setInterval(() => scrollHorizontally(-30), 100);
        }
    }

    const clearScrollInterval = () => {
        clearInterval(intervalId.value);
    }

    function TabNavsWrapper(props, ctx) {
        return props.disabled 
            ? ctx.slots.default()
            : h('div', { class: 'position-relative bg-transparent d-flex', style: { minHeight: 'initial' }}, ctx.slots.default());
    }
    TabNavsWrapper.props = ['disabled'];


    defineExpose({ setActiveTab, getActiveTab, sm, md, lg, compactView, tabsControl });
</script>

<style scoped>
.tabsContainer::-webkit-scrollbar {
    display: none;
}

.left-arrow,
.right-arrow {
    background: transparent;
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 25%;
    transform: translateY(50%);
    height: 25%;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.left-arrow {
    left: 0;
    z-index: 100;
}

.right-arrow {
    right: 0;
}
</style>